<template>
    <div style="background-color: #ffffff;text-align: left;padding: 20px;border-radius: 5px;">
        <template v-if="1||queryFurnitureLibraryObj && queryFurnitureLibraryObj.content && queryFurnitureLibraryObj.content.length">
            <div class="pageCenter text-left m-b-20" style="min-height: 500px;">
                <div class="df-jc-s-b">
                    <el-button-group>
                        <el-button v-for="v of orderStatus" :key="v.id" @click="setroomAuthStatus(v.id)" :type="queryFurnitureLibrary.roomAuthStatus==v.id?'primary':''" size="mini">{{v.text}}</el-button>
                    </el-button-group>
                    <el-button type="primary" size="mini" @click="$oucy.go('/acc/liveSave')">创建直播</el-button>
                </div>
                <div class="m-t-10" v-if="queryFurnitureLibraryObj.content">
                    <div v-for="(v,i) of queryFurnitureLibraryObj.content" :key="v.id" class="content">
                        <div class="top df-jc-s-b">
                            <div class="left"><img :src="$oucy.ossUrl + v.roomIcon" alt="">
                                <span class="roomTool">
                                    <i v-if="v.roomTool==0" class="el-icon-mobile-phone"></i>
                                    <i v-if="v.roomTool==1" class="el-icon-monitor"></i>
                                </span>
                            </div>
                            <div class="right">
                                <div>
                                    <div class="title df-jc-s-b">
                                        <div class="title_left pointer" @click="gotoLive(v)">{{v.roomTitle}}</div>
                                        <div class="title_right" v-if="v.roomStatus!=4">
                                            <el-button type="text" size="mini" icon="el-icon-delete" @click="delLiveRoom(v,i)"></el-button>
                                            <el-button type="" size="mini" @click="$oucy.go('/acc/liveSave?id='+v.id)">编辑</el-button>
                                            <el-button type="warning" size="mini" @click="gotoLive(v)" v-if="v.roomAuthStatus==1">控制台</el-button>
                                        </div>
                                    </div>
                                    <div class="info">
                                        <div class="info_left">主持人</div>
                                        <div class="info_right">{{v.ltUserAuthBasic.detailNickname}}</div>
                                    </div>
                                    <div class="info">
                                        <div class="info_left">开播时间</div>
                                        <div class="info_right">{{v.roomTimeBeginStart}}--{{v.roomTimeBeginEnd}}</div>
                                    </div>
                                    <div class="info">
                                        <div class="info_left">直播间号</div>
                                        <div class="info_right">{{v.roomNumber}}</div>
                                    </div>
                                    <div class="info">
                                        <liveType :data="v"></liveType>
                                    </div>
                                </div>
                                <div class="bottom">
                                    <div class="bottomBoxs df">
                                        <div class="bottomBox" title="点赞次数">
                                            <div class="bottomBox_top">{{v.liveRoomPBasic && v.liveRoomPBasic.plike||0}}</div>
                                            <div>点赞</div>
                                        </div>
                                        <div class="bottomBox" title="回复及发送信息数">
                                            <div class="bottomBox_top">{{v.liveRoomPBasic && v.liveRoomPBasic.pinteract||0}}</div>
                                            <div>互动</div>
                                        </div>
                                        <div class="bottomBox" title="分享次数">
                                            <div class="bottomBox_top">{{v.liveRoomPBasic && v.liveRoomPBasic.pshare||0}}</div>
                                            <div>分享</div>
                                        </div>
                                        <div class="bottomBox" title="新增关注主播人数">
                                            <div class="bottomBox_top">{{v.liveRoomPBasic && v.liveRoomPBasic.pattention||0}}</div>
                                            <div>关注</div>
                                        </div>
                                        <div class="bottomBox" title="当前正在观看人数">
                                            <div class="bottomBox_top">{{v.liveRoomPBasic && v.liveRoomPBasic.ponline||0}}</div>
                                            <div>在线</div>
                                        </div>
                                        <div class="bottomBox" title="观看总次数">
                                            <div class="bottomBox_top">{{v.liveRoomPBasic && v.liveRoomPBasic.pwatch||0}}</div>
                                            <div>观看</div>
                                        </div>
                                        <div class="bottomBox" title="推送商品、店铺、名片、优惠券次数">
                                            <div class="bottomBox_top">{{v.liveRoomPBasic && v.liveRoomPBasic.ppush||0}}</div>
                                            <div>推送</div>
                                        </div>
                                        <div class="bottomBox" title="推送的商品、店铺、名片、优惠券点击次数">
                                            <div class="bottomBox_top">{{v.liveRoomPBasic && v.liveRoomPBasic.pclick||0}}</div>
                                            <div>点击</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="p-50 c-2 text-center">还没有直播</div>
            </div>
            <div class="pagination" style="background-color: #ffffff;padding: 20px 0;">
                <el-pagination v-if="queryFurnitureLibraryObj.content" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="queryFurnitureLibraryObj.size" :total="queryFurnitureLibraryObj.totalElements" @size-change="sizeChange" @current-change="currentChange" @prev-click="currentChange" @next-click="currentChange">
                </el-pagination>
            </div>
        </template>
        <template v-else>
            <div class="card">
                <div>
                    <img src="@/assets/images/photo/class_not.png">
                    <div class="f14 c-2 m-b-20">当前还未有直播</div>
                    <el-button type="primary" size="mini" @click="$oucy.go('/acc/liveSave')">立即创建</el-button>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import { schemeOrder, liveroom } from '@/service/index.js'
import { localSet, localGet, localDel } from "@/store/store"

export default {
    name: "Enter",
    data() {
        return {
            pageWidth: oucy.pageWidth,
            //  审核状态：0待审核 1审核通过 2审核驳回
            orderStatus: [
                { text: '全部', id: null },
                { text: '待审核', id: 0 },
                { text: '已审核', id: 1 },
                { text: '已驳回', id: 2 },
            ],


            queryFurnitureLibraryObj: {},
            // 请求对象
            queryFurnitureLibrary: {
                roomAuthStatus: null, //    审核状态：0待审核 1审核通过 2审核驳回
                limit: 10,
                start: 0,
                roomTitle: null,
            },
        }
    },
    computed: {

    },
    mounted() {

        this.queryUserLiveRoom()
    },
    methods: {
        // 查询用户的直播列表
        queryUserLiveRoom() {
            liveroom.queryUserLiveRoom(this.queryFurnitureLibrary).then(res => {
                if (res) {
                    this.queryFurnitureLibraryObj = res
                }
                console.log(res)
            }, err => {
                console.error(err)
            })
        },
        delLiveRoom(v, i) {
            this.$oucy.confirm('确认删除直播？', '提示').then(res => {
                liveroom.delLiveRoom({ id: v.id }).then(res => {
                    this.queryFurnitureLibraryObj.content.splice(i, 1)
                        --this.queryFurnitureLibraryObj.totalElements
                    this.$message('操作成功')
                })
            }, err => {})
        },
        setroomAuthStatus(v) {
            this.queryFurnitureLibrary.roomAuthStatus = v
            this.queryUserLiveRoom()
        },
        // 切换页数
        sizeChange: function(pageSize) {
            const me = this;
            me.queryFurnitureLibrary.limit = pageSize;
            me.queryUserLiveRoom();
        },
        // 切换页码
        currentChange: function(current) {
            const me = this;
            me.queryFurnitureLibrary.start = current - 1;
            me.queryUserLiveRoom();
        },
        gotoLive(v){
            if(v.roomAuthStatus==1){
                this.$oucy.go('/live?id='+v.id)
            }else{
                this.$alert('直播还没通过审核，请耐心等待')
            }
        }


    }
}
</script>
<style scoped lang="less">
.card {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    min-height: 700px;
    text-align: center;
}

.content {
    padding: 20px 10px;

    &:hover {
        background: #F8F8F8;

        .bottomBox {
            background: #fff;
        }
    }

    border-top:1px dashed #f1f1f1;
}

.left {
    width: 410px;
    position:relative;
    // flex: 1;
    /*height: 148px;*/

    img {
        width: 100%;
        /*width: 263px;*/
        /*height: 148px;*/
        border-radius: 6px;
    }
    .roomTool{
        position:absolute;
        right: 10px;
        top:10px;
    }
}

.right {
    flex: 1;
    padding-left: 20px;
}

.title_left {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 21px;
    color: #555C68;
}

.info {
    display: flex;
    margin-top: 5px;
}

.info_left {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    /*line-height: 21px;*/
    color: #B0BBC5;
    /*letter-spacing: 500px;*/
    /*text-align: justify;*/
    display: inline-block;
    text-indent: justify;
    text-align-last: justify;
    width: 56px;
}

.info_right {
    font-size: 14px;
    margin-left: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    /*line-height: 21px;*/
    color: #555C68;
}

.bottomBoxs {
    text-align: center;
    margin-top: 20px;
}

.bottomBox {
    width: 70px;
    box-sizing: border-box;
    padding: 20px;
    /*height: 80px;*/
    background: #F8F8F8;
    opacity: 1;
    border-radius: 15px;
    margin-right: 10px;

    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 21px;
    color: #B0BBC5;

    .bottomBox_top {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 21px;
        color: #555C68;
    }
}
</style>